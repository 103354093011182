import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Box, Text } from '@primer/react';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/231862745-5099ccae-9f65-4a8e-a6f5-cf5c1b6ba3c2.png" />
    <h2>{`Usage`}</h2>
    <p>{`Tab panels are used to group related content into views that are shown and hidden. Each view has a corresponding tab that provides a label and controls the panel's visibility.`}</p>
    <p>{`Tabs in a tab panel should not be links that navigate the user to a new URL. If you want tab-like navigation, use a `}<a parentName="p" {...{
        "href": "/components/tab-nav"
      }}>{`tab nav`}</a>{` instead.`}</p>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      alignItems: "center",
      gap: 3
    }} mdxType="Box">
  <Box flex={1} mdxType="Box">
        <p>{`For example, a tab panel is used to switch between the "Branches" and "Tags" views in the branch selection dialog.`}</p>
  </Box>
  <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/231862746-9f35f3a6-6f31-4733-b1af-2bad9cd84745.png" />
    </Box>
    <h3>{`Best practices`}</h3>
    <p>{`The same best practices apply to tab panels as they do to `}<a parentName="p" {...{
        "href": "/components/tab-nav#best-practices"
      }}>{`tab nav`}</a>{`.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/231862747-ea4e4b3b-847f-4ead-8b73-aa9bfc99fb32.png" />
    <p><strong parentName="p">{`Tab list:`}</strong>{` A set of buttons used to switch between views. Each tab should concisely label the view it's associated with.`}</p>
    <p><strong parentName="p">{`Tab panel:`}</strong>{` The view associated with the active tab.`}</p>
    <h2>{`Options`}</h2>
    <p>{`The same options are available to tab panels as they are to `}<a parentName="p" {...{
        "href": "/components/tab-nav#options"
      }}>{`tab nav`}</a>{`.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Keyboard navigation`}</h3>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box flex={1} mdxType="Box">
        <p>{`Tabs, like all interactive elements, can be activated using a cursor or keyboard.`}</p>
        <p>{`When a user moves focus into the `}<inlineCode parentName="p">{`tablist`}</inlineCode>{` using the Tab key, the active tab should be focused. A `}<inlineCode parentName="p">{`tablist`}</inlineCode>{` is only a single Tab stop. Once focused, navigation through the `}<inlineCode parentName="p">{`tablist`}</inlineCode>{` can then conducted with Left, Right, Home, and End keypresses. `}</p>
  </Box>
  <CustomVideoPlayer width="456" loop src="https://user-images.githubusercontent.com/2313998/231858246-ea2439b5-9e49-4b9b-a047-5ea05b0d2b4c.mp4" mdxType="CustomVideoPlayer" />
    </Box>
    <h3>{`Other a11y guidance`}</h3>
    <p>{`Tab nav and tab panels are almost identical. Reference the `}<a parentName="p" {...{
        "href": "/components/tab-nav#accessibility"
      }}>{`tab nav accessibility guidelines`}</a>{` for more guidance.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box as="kbd" backgroundColor="canvas.subtle" borderRadius={1} borderColor="border.default" borderWidth={1} borderStyle="solid" py={1} px={2} mdxType="Box">{`Tab`}</Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus from the tablist to the tab panel or next focusable node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box as="kbd" backgroundColor="canvas.subtle" borderRadius={1} borderColor="border.default" borderWidth={1} borderStyle="solid" py={1} px={2} mdxType="Box">{`Enter`}</Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Activates the focused tab.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box as="kbd" backgroundColor="canvas.subtle" borderRadius={1} borderColor="border.default" borderWidth={1} borderStyle="solid" py={1} px={2} mdxType="Box">{`ArrowLeft`}</Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Activates the previous tab. If a user is focused on the first tab, wrap to activate the last tab.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box as="kbd" backgroundColor="canvas.subtle" borderRadius={1} borderColor="border.default" borderWidth={1} borderStyle="solid" py={1} px={2} mdxType="Box">{`ArrowRight`}</Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Activates the mext tab. If a user is focused on the last tab, wrap to activate the first tab.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="TabPanels" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tab-nav"
        }}>{`Tab nav`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/underline-nav"
        }}>{`Underline nav`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      